import { httpClient } from "store/api_clients/http_client";
import { convertToCamelCase } from "store/utils/case_converter";

export const stripeTokenizationApiClient = {
  connect: async ({ installationId }: { installationId: string }) => {
    const path = `applications/stripe_tokenization_app/${installationId}/connect`;

    const response = await httpClient.send("POST", path);

    return convertToCamelCase(response.data);
  }
}
