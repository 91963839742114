import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { Button, Result } from "antd";
import { stripeTokenizationApiClient } from "store/api_clients/applications/stripe_tokenization";

const useConnectStripe = () => {
  return useMutation({
    mutationFn: async (params: { installationId: string }) => {
      const { link } = await stripeTokenizationApiClient.connect(params);

      window.location.href = link;
    },
  });
}

type SettingsParams = {
  installation: {
    id: string,
    applicationCode: string,
    applicationId: string,
    isActive: boolean,
    propertyId: string,
    settings: Record<string, any>
  }
}
export const StripeTokenizationSettings = ({ installation }: SettingsParams) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const isConnected = !!installation.settings.accountId;

  const connectStripe = useConnectStripe();

  const handleClick = () => {
    const perform = async () => {
      await connectStripe.mutateAsync({ installationId: installation.id });
      setLoading(true);
    }

    void perform();
  }

  return (
    <>
      {isConnected && (
        <Result
          status="success"
          title={t("stripe_tokenization:messages:successfully_connected")}
        />
      )}
      {isConnected || (
        <Button type="primary" loading={loading} onClick={handleClick}>
          {t("stripe_tokenization:actions:connect")}
        </Button>
      )}
    </>
  );
}
