import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import store from "store";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";
import withRouter from "routing/with_router";

import { applicationsConfiguration } from "./configuration";

const { Applications } = store;

function ApplicationSettings(props) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [installation, setInstallation] = useState(null);
  const { userAppRoutes } = useRouting();
  const {
    navigate,
    params: { installationId },
  } = props;

  const onClose = () => {
    setVisible(false);
    setTimeout(() => {
      navigate(pathBuilder(userAppRoutes.applications, {}));
    }, 300);
  };

  useEffect(function setDrawerVisibility() {
    setVisible(true);
  }, []);

  useEffect(
    function loadInstallation() {
      if (!installation) {
        Applications.find(installationId).then((application_installation) => {
          setInstallation(application_installation);
        });
      }
    },
    [installationId, installation],
  );

  let title;
  let content;

  if (installation) {
    title = t(`applications_page:${installation.applicationCode}:settings_header`);
    const applicationConfig = applicationsConfiguration[installation.applicationCode];

    content = React.createElement(applicationConfig.SettingsComponent, {
      installation,
      onClose,
    });
  } else {
    title = t("general:loading");
    content = null;
  }
  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {({ _handleCloseFromContent }) => (
        <DrawerFormWrapper withActions>{content}</DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}

const mapStateToProps = ({ applications }) => {
  return { applications };
};

export default withRouter(connect(mapStateToProps)(ApplicationSettings));
