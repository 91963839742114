import { ComponentType } from "react";

import ApaleoSettings from "./apaleo";
import AthenaSettings from "./athena";
import BusyRoomsSettings from "./busy_rooms";
import ChannexPCISettings from "./channex_pci";
import GoldenUpSettings from "./golden_up";
import HotelNabeSettings from "./hotel_nabe";
import MakeSettings from "./make";
import { MewsSettings } from "./mews";
import OperaSettings from "./opera";
import RateTigerSettings from "./rate_tiger";
import RoomCloudSettings from "./room_cloud";
import RoomPriceGenie from "./room_price_genie";
import ShalomSettings from "./shalom";
import StaahSettings from "./staah";
import { StripeTokenizationSettings } from "./stripe_tokenization";
import WiseSettings from "./wise";
import YieldPlanetSettings from "./yield_planet";
import ZapierSettings from "./zapier";

type AppConfig = {
  SettingsComponent: ComponentType<any>,
  openSettingsAfterInstall?: boolean
}

export const applicationsConfiguration: Record<string, AppConfig> = {
  apaleo: {
    SettingsComponent: ApaleoSettings,
  },
  athena: {
    SettingsComponent: AthenaSettings,
  },
  busy_rooms: {
    SettingsComponent: BusyRoomsSettings,
  },
  golden_up: {
    SettingsComponent: GoldenUpSettings,
  },
  hotel_nabe: {
    SettingsComponent: HotelNabeSettings,
  },
  shalom: {
    SettingsComponent: ShalomSettings,
  },
  wise: {
    SettingsComponent: WiseSettings,
  },
  yield_planet: {
    SettingsComponent: YieldPlanetSettings,
  },
  mews: {
    SettingsComponent: MewsSettings,
  },
  mews_reserva: {
    SettingsComponent: MewsSettings,
  },
  opera: {
    SettingsComponent: OperaSettings,
  },
  rate_tiger: {
    SettingsComponent: RateTigerSettings,
  },
  room_cloud: {
    SettingsComponent: RoomCloudSettings,
  },
  staah: {
    SettingsComponent: StaahSettings,
  },
  channex_pci: {
    SettingsComponent: ChannexPCISettings,
  },
  zapier: {
    SettingsComponent: ZapierSettings,
  },
  make_com: {
    SettingsComponent: MakeSettings,
  },
  room_price_genie: {
    SettingsComponent: RoomPriceGenie,
  },
  stripe_tokenization: {
    SettingsComponent: StripeTokenizationSettings,
    openSettingsAfterInstall: true
  },
}
