import React from "react";
import { useNavigate } from "react-router";
import { AppstoreOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

import ChannexModal from "components/channex_modal";

import useBoolState from "utils/use_bool_state";

import { applicationsConfiguration } from "../../../application_settings/configuration";

import PropertyID from "./fields/property_id";
import Footer from "./applications_view_modal_footer";

import styles from "./applications_view_modal.module.css";

const ICON_SIZE = 128;

const fieldComponents = {
  propertyID: PropertyID,
};

export default function ApplicationsViewModal({
  visible,
  item,
  onInstall,
  onUninstall,
  onClose,
  canManageApplications,
}) {
  const [loading, setLoading, setLoadingComplete] = useBoolState();
  const { logoUrl, title, description } = item;
  const representationSettings = item.representationSettings || {};
  const fields = representationSettings.fields || [];

  const navigate = useNavigate();

  const handleLoadingComplete = () => {
    setLoadingComplete();
    onClose();
  };

  const handleInstall = () => {
    setLoading();

    onInstall(item.applicationId)
      .then((installedApp) => {
        const applicationConfig = applicationsConfiguration[installedApp.applicationCode];

        if (applicationConfig.openSettingsAfterInstall) {
          navigate(`/applications/${installedApp.id}`);
        }

        return installedApp;
      })
      .finally(handleLoadingComplete);
  };

  const handleUninstall = () => {
    setLoading();

    onUninstall(item.installationId).finally(handleLoadingComplete);
  };

  return (
    <ChannexModal
      title={title}
      visible={visible}
      closable={false}
      footer={
        <Footer
          item={item}
          loading={loading}
          onInstall={handleInstall}
          onUninstall={handleUninstall}
          canManageApplications={canManageApplications}
          onClose={onClose}
        />
      }
    >
      <div className={styles.infoContainer}>
        <Avatar
          data-testid="app_icon"
          className={styles.icon}
          size={ICON_SIZE}
          src={logoUrl}
          icon={<AppstoreOutlined />}
        />
        <div>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {fields
            .filter((field) => fieldComponents[field])
            .map((field) => React.createElement(fieldComponents[field], { key: field }))}
        </div>
      </div>
    </ChannexModal>
  );
}
